import React from "react"
import styled from "styled-components"
import { graphql, Link } from "gatsby"
import marked from "marked"

import Button from "../components/Button"
import Hero from "../components/Hero"
import Page from "../components/Page"
import Carousel from "../components/Carousel"
import ShowItem from "../components/ShowItem"
import TeamItem from "../components/TeamItem"
import MainWrapper from "../components/MainWrapper"
import { Default, Skewed } from "../components/PageSections"

import Typer from "../components/page-specific/Home/Typer"
import QuoteCard from "../components/page-specific/Home/QuoteCard"

import { themer } from "../styles/helpers"

import homeData from "../data/home.json"
import { consoleLiteral } from "../data/consoleLiteral"

const MainHero = styled(Hero)`
  margin-top: -${themer("constants.navBarHeight")};
`

const Introduction = styled(Skewed)`
  padding: 10rem 0 3rem 0;
  margin: 10rem 0;
  &:before {
    background: ${themer("shade.lightest")};
  }
  button {
    display: block;
    margin: 1rem auto;
    margin-top: 4rem;
  }
  .self-description {
    a {
      position: relative;
      &:before {
        ${themer("before")}
        background: ${themer("accent")};
        height: 20%;
        top: 85%;
      }
    }
    pre {
      overflow-x: auto;
      padding: 0.75rem;
      border-radius: 0.5rem;
      background: ${themer("shade.darkest")};
    }
  }
`

const Director = styled(Default)`
  color: ${themer("shade.lightest")};
  padding-top: 2rem;
`

const PanelWrapper = styled(MainWrapper)`
  display: flex;
  flex-flow: row wrap;
`

const Showcase = styled(Skewed)`
  padding: 3rem 0 15rem;
  margin: 15rem 0;
  &:before {
    background: ${themer("shade.lightest")};
  }
`

const Home = ({ data }) => {
  const { featured, team } = data
  const { nodes: showcaseItems } = featured
  const { nodes: teamItems } = team
  /* eslint-disable no-console */
  console.clear()
  console.log(consoleLiteral)
  /* eslint-enable no-console */
  return (
    <Page accentKey="teal" bgDesign="space" seoProfile="home-page">
      <MainHero expanding>
        <p>We are a collective of content creators called</p>
        <h1>
          <span>Kudonate</span>
        </h1>
        <p>and we describe ourselves as</p>
        <br />
        <h2>
          <Typer descriptors={homeData.descriptors} />
        </h2>
      </MainHero>
      <Introduction skew="4deg">
        <h2 className="title">Meet The Team</h2>
        <PanelWrapper>
          {teamItems.map(
            ({ frontmatter: { slug, type, ...showItemProps } }) => {
              const showCaseLink = `/team/${slug}`
              return (
                <TeamItem
                  key={showCaseLink}
                  to={showCaseLink}
                  type="team"
                  size="small"
                  includeType
                  {...showItemProps}
                />
              )
            }
          )}
          <Button>
            <Link to="/about">More about us</Link>
          </Button>
        </PanelWrapper>
      </Introduction>
      <Director>
        <h2 className="title">Featured Content</h2>
        <Carousel>
          {showcaseItems.map(
            ({ frontmatter: { slug, type, ...showItemProps } }) => {
              const showCaseLink = `/${type}/${slug}`
              return (
                <ShowItem
                  key={showCaseLink}
                  to={showCaseLink}
                  type={type}
                  size="large"
                  includeType
                  {...showItemProps}
                />
              )
            }
          )}
        </Carousel>
      </Director>
    </Page>
  )
}

export const SHOWCASE_QUERY = graphql`
  query {
    featured: allMarkdownRemark(
      filter: { frontmatter: { showcase: { eq: true } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        frontmatter {
          title
          type
          description
          image
          slug
          showcase
        }
      }
    }
    team: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "team" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        frontmatter {
          title
          type
          description
          image
          slug
          showcase
        }
      }
    }
  }
`

export default Home
