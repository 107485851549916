export const consoleLiteral = `
██╗      ██████╗  ██████╗ ██╗  ██╗███████╗    ██╗     ██╗██╗  ██╗███████╗    ██╗    ██╗███████╗      
██║     ██╔═══██╗██╔═══██╗██║ ██╔╝██╔════╝    ██║     ██║██║ ██╔╝██╔════╝    ██║    ██║██╔════╝      
██║     ██║   ██║██║   ██║█████╔╝ ███████╗    ██║     ██║█████╔╝ █████╗      ██║ █╗ ██║█████╗        
██║     ██║   ██║██║   ██║██╔═██╗ ╚════██║    ██║     ██║██╔═██╗ ██╔══╝      ██║███╗██║██╔══╝        
███████╗╚██████╔╝╚██████╔╝██║  ██╗███████║    ███████╗██║██║  ██╗███████╗    ╚███╔███╔╝███████╗      
╚══════╝ ╚═════╝  ╚═════╝ ╚═╝  ╚═╝╚══════╝    ╚══════╝╚═╝╚═╝  ╚═╝╚══════╝     ╚══╝╚══╝ ╚══════╝      
                                                                                                     
██╗  ██╗ █████╗ ██╗   ██╗███████╗     █████╗     ██╗  ██╗ █████╗  ██████╗██╗  ██╗███████╗██████╗     
██║  ██║██╔══██╗██║   ██║██╔════╝    ██╔══██╗    ██║  ██║██╔══██╗██╔════╝██║ ██╔╝██╔════╝██╔══██╗    
███████║███████║██║   ██║█████╗      ███████║    ███████║███████║██║     █████╔╝ █████╗  ██████╔╝    
██╔══██║██╔══██║╚██╗ ██╔╝██╔══╝      ██╔══██║    ██╔══██║██╔══██║██║     ██╔═██╗ ██╔══╝  ██╔══██╗    
██║  ██║██║  ██║ ╚████╔╝ ███████╗    ██║  ██║    ██║  ██║██║  ██║╚██████╗██║  ██╗███████╗██║  ██║    
╚═╝  ╚═╝╚═╝  ╚═╝  ╚═══╝  ╚══════╝    ╚═╝  ╚═╝    ╚═╝  ╚═╝╚═╝  ╚═╝ ╚═════╝╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝    
                                                                                                     
██╗███╗   ██╗     ██████╗ ██╗   ██╗██████╗     ███╗   ███╗██╗██████╗ ███████╗████████╗               
██║████╗  ██║    ██╔═══██╗██║   ██║██╔══██╗    ████╗ ████║██║██╔══██╗██╔════╝╚══██╔══╝               
██║██╔██╗ ██║    ██║   ██║██║   ██║██████╔╝    ██╔████╔██║██║██║  ██║███████╗   ██║                  
██║██║╚██╗██║    ██║   ██║██║   ██║██╔══██╗    ██║╚██╔╝██║██║██║  ██║╚════██║   ██║                  
██║██║ ╚████║    ╚██████╔╝╚██████╔╝██║  ██║    ██║ ╚═╝ ██║██║██████╔╝███████║   ██║                  
╚═╝╚═╝  ╚═══╝     ╚═════╝  ╚═════╝ ╚═╝  ╚═╝    ╚═╝     ╚═╝╚═╝╚═════╝ ╚══════╝   ╚═╝                  
`

export default consoleLiteral
